import { ProposalsState } from 'src/assets/types/data/proposal'
import { ActiveProposalState } from 'src/assets/types/data/proposal/activeProposal'
import { MovingApplicationsState } from 'src/assets/types/data/proposal/movingApplications'
import {
  StoreState,
  StoreImageState,
  StoreHolidayState
} from 'src/assets/types/data/proposal/store'

export const ActionTypes = {
  SET_ACTIVE_PROPOSAL: 'proposal/SET_ACTIVE_PROPOSAL',
  SET_STORE: 'proposal/SET_STORE',
  SET_STORE_IMAGE: 'proposal/SET_STORE_IMAGE',
  SET_STORE_HOLIDAY: 'proposal/SET_STORE_HOLIDAY',
  SET_MOVING_APPLICATIONS: 'proposal/SET_MOVING_APPLICATIONS',
  SET_ACTIVE_MOVING_APPLICATIONS: 'proposal/SET_ACTIVE_MOVING_APPLICATIONS',
  SET_PAYMENT_PROMISES: 'proposal/SET_PAYMENT_PROMISES',
  SET_MAIN_EXAMINATION_SCORE: 'proposal/SET_MAIN_EXAMINATION_SCORE',
  SET_PROPOSALS_TODOS: 'proposal/SET_PROPOSALS_TODOS'
} as const

export const setActiveProposal = (
  activeProposal: Partial<ActiveProposalState>
) => ({
  type: ActionTypes.SET_ACTIVE_PROPOSAL,
  payload: activeProposal
})

export const setStore = (payload: StoreState) => ({
  type: ActionTypes.SET_STORE,
  payload
})

export const setStoreImage = (payload: StoreImageState) => ({
  type: ActionTypes.SET_STORE_IMAGE,
  payload
})

export const setStoreHoliday = (payload: StoreHolidayState) => ({
  type: ActionTypes.SET_STORE_HOLIDAY,
  payload
})

export const setMovingApplications = (
  payload: Partial<MovingApplicationsState['movingApplications']>
) => ({
  type: ActionTypes.SET_MOVING_APPLICATIONS,
  payload
})

export const setActiveMovingApplications = (
  payload: Partial<MovingApplicationsState['activeMovingApplication']>
) => ({
  type: ActionTypes.SET_ACTIVE_MOVING_APPLICATIONS,
  payload
})

export const setTempPaymentPromises = (
  payload: Partial<MovingApplicationsState['tempPaymentPromises']>
) => ({
  type: ActionTypes.SET_PAYMENT_PROMISES,
  payload
})

export const setMainExaminationScore = (
  payload: Partial<ProposalsState['mainExaminationScore']>
) => ({
  type: ActionTypes.SET_MAIN_EXAMINATION_SCORE,
  payload
})

export const setProposalsTodos = (
  payload: Partial<ProposalsState['proposalsTodos']>
) => ({
  type: ActionTypes.SET_PROPOSALS_TODOS,
  payload
})

export type Actions =
  | ReturnType<typeof setActiveProposal>
  | ReturnType<typeof setStore>
  | ReturnType<typeof setStoreImage>
  | ReturnType<typeof setStoreHoliday>
  | ReturnType<typeof setMovingApplications>
  | ReturnType<typeof setActiveMovingApplications>
  | ReturnType<typeof setTempPaymentPromises>
  | ReturnType<typeof setMainExaminationScore>
  | ReturnType<typeof setProposalsTodos>
